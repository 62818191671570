<template>
  <div class="container container-m">
    <card title="user.activation.title">
      <p>
        {{translateUcFirst('user.activation.manual.explain')}}
      </p>
    </card>
  </div>
  <div>

  </div>
</template>

<script>
export default {
  data: function() {
    return {};
  },
  meta: "user.activation.pageTitle",
  layout: {
    component: "default",
    params: { },
  },

};
</script>

<style scoped lang="scss">

</style>
